<script>
import MixinsAccountManagement from "../../components/MixinsAccountManagement.vue";

export default {
  name: "Assets",
  mixins: [MixinsAccountManagement],
  computed: {
    mode() {
      return "assets";
    },
  },
};
</script>

<style scoped></style>
